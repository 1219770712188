import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import ButtonFlowList from "./ButtonFlowList";
import AdaptiveSelectCard from "./AdaptiveSelectCard";
import {
  ScheduleConfirmationCard,
  ScheduleTimeSlotSelection,
  TimeSlotsMenuSelection,
} from "./PcpSchedulingCard";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { RefillConfirmation } from "./RefillConfirmation";
import { Authentication, MFACode } from "./Authentication";
import { TierTwoButton } from "./TierTwoButton";
import { FormInputcard } from "./FormInput";

const useStyles = makeStyles(() => ({
  card: {
    width: 253,
    borderRadius: "8px 8px 8px 0px",
    border: "1px solid var(--Neutral-Gray---100, #DAE2EA)",
    background: "var(--White, #FFF)",
  },
  button_flow_list: {
    width: 296,
  },

  billing_refining_questions: {
    width: 296,
  },
  find_care_refining_questions: {
    width: 260,
  },
  chipsContent: {
    display: "flex",
    flexWrap: "wrap",
    padding: 16,
    rowGap: 8,
    columnGap: 4,
    fontSize: 12,
  },
  list_button: {
    borderRadius: "8px !important",
    height: "52px !important",
  },
  textContent: {
    fontSize: 14,
    padding: 8,
    fontWeight: 500,
    lineHeight: "125%",
    letterSpacing: "0.11px",

    "& a": {
      color: "var(--Primary-Blue---500, #006298)",
      fontWeight: 500,
      textDecoration: "none",
      letterSpacing: "0.11px",

      "&:hover": {
        textDecoration: "underline",
      },

      "&:visited": {
        textDecoration: "none",
        color: "color: var(--Accent-B-Purple---500, #8B3C7C)",
      },
    },
  },
  timeframe: {
    "&:not(:last-child)": {
      borderBottom: "1px solid #DAE2EA !important",
    },
  },
}));

export default function CustomCard({ card, next, handleChipClick }) {
  const classes = useStyles();
  const cardContent = (card) => {
    switch (card.attachment.content?.subtype) {
      case "button_flow_list":
      case "billing_refining_questions":
      case "find_care_refining_questions":
        return (
          <div
            className={`${classes.card} ${
              classes[card.attachment.content?.subtype]
            }`}
          >
            <ButtonFlowList
              actions={card.attachment.content.actions}
              handleChipClick={handleChipClick}
            />
          </div>
        );

      case "account_verification_options":
      case "yes_no":
      case "schedule_timeframe_selection":
      case "account_mfa_sendcode":
      case "tier_two_default_subtype":
      case "chat_language":
        return (
          <div className={classes.card}>
            <TierTwoButton
              actions={card.attachment.content.actions}
              handleChipClick={handleChipClick}
            />
          </div>
        );
      case "account_sign_in":
        return (
          <div className={classes.card}>
            <Authentication
              content={card.attachment.content}
              handleClick={handleChipClick}
            />
          </div>
        );

      case "chat_name":
      case "chat_phone_zip":
      case "schedule_symptoms_request":
        return (
          <div className={classes.card}>
            <FormInputcard
              content={card.attachment.content}
              handleClick={handleChipClick}
            />
          </div>
        );

      case "schedule_location_selection":
      case "refill_medication_selection":
      case "refill_pharmacy_selection":
        return (
          <div className={classes.card}>
            <AdaptiveSelectCard
              content={card.attachment.content}
              handleClick={handleChipClick}
            />
          </div>
        );

      case "account_mfa_entercode":
        return (
          <div className={classes.card}>
            <MFACode
              content={card.attachment.content}
              handleClick={handleChipClick}
            />
          </div>
        );

      case "refill_confirmation":
        return (
          <div className={classes.card}>
            <RefillConfirmation
              content={card.attachment.content}
              handleClick={handleChipClick}
            />
          </div>
        );

      case "schedule_timeslot_menu_selection":
        return (
          <TimeSlotsMenuSelection
            content={card.attachment.content}
            handleClick={handleChipClick}
          />
        );

      case "schedule_timeslot_selection":
        return (
          <ScheduleTimeSlotSelection
            content={card.attachment.content}
            handleClick={handleChipClick}
          />
        );

      case "schedule_confirmation":
        return (
          <div className={classes.card}>
            <ScheduleConfirmationCard
              content={card.attachment.content}
              handleClick={handleChipClick}
            />
          </div>
        );

      default:
        return next(card);
    }
  };

  return card?.attachment?.contentType ===
    "application/vnd.microsoft.card.adaptive" ? (
    cardContent(card)
  ) : (
    <div className={classes.card}>
      <div
        className={classes.textContent}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(marked(card.attachment.content), {
            ALLOWED_TAGS: ["a", "br"],
          }),
        }}
      />
    </div>
  );
}

CustomCard.propTypes = {
  directLine: PropTypes.object,
  card: PropTypes.object,
};
