import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Chip } from "@mui/material";

const useStyles = makeStyles(() => ({
  chipsContent: {
    display: "flex",
    flexWrap: "wrap",
    padding: 16,
    rowGap: 8,
    columnGap: 4,
    fontSize: 12,
  },

  chip: {
    backgroundColor: "white",
    "& .MuiChip-label": {
      fontSize: "12px",
      color: "var(--Neutral-Gray---500, #536E88)",
    },
    "& .MuiChip-outlined:": {
      border: "1px solid var(--Neutral-Gray---400, #758BA0)",
    },

    "& .MuiChip-outlined.MuiChip-clickable": {
      "&:focus": {
        background:
          "var(--States-Secondary-Overlay-Selected, rgba(24, 156, 210, 0.08))",
        border: "1px solid var(--Primary-Blue---200, #75C9EB)",
        "& .MuiChip-label": {
          color: "var(--Primary-Blue---300, #189CD2)",
        },
      },
      "&:hover": {
        background:
          "var(--States-Dark-Overlay-Hovered, rgba(83, 110, 136, 0.04))",
      },
    },
  },
}));

export default function ButtonFlowList({ actions, handleChipClick }) {
  const classes = useStyles();

  return (
    <div className={classes.chipsContent}>
      {actions?.map((action) => (
        <div className={classes.chip}>
          <Chip
            label={action.title}
            onClick={() => handleChipClick(action)}
            variant="outlined"
          />
        </div>
      ))}
    </div>
  );
}

ButtonFlowList.propTypes = {
  directLine: PropTypes.object,
  card: PropTypes.object,
};
