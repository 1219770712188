import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Typography } from "@mui/material";
import { CustomButton } from "./customButton";
import { getVariant } from "../helpers/utils";

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    gap: 16,
    "& .MuiButtonBase-root": {
      width: "100%",
      borderRadius: "100px",
      fontSize: 14,
      fontWeight: 600,
      textTransform: "none !important",
    },
  },
  columns: {
    display: "flex",
    gap: 16,
  },
  avatar: {
    borderRadius: 48,
    border: "2px solid var(--Neutral-Gray---100, #DAE2EA)",
    background:
      "var(--Avatar-Provider-Female-01, url(<path-to-image>) lightgray 50% / contain no-repeat)",
  },
  title: {
    color: "#006298",
    fontSize: 14,
    fontWeight: "600 !important",
    lineHeight: "125%",
    lineSpacing: "0.11px",
    fontFamily: "Montserrat !important",
  },
  subtitle: {
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "125% !important",
    lineSpacing: "0.11px !important",
    color: "var(--Neutral-Gray---700, #192129)",
    fontFamily: "Montserrat !important",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    "& .MuiButtonBase-root": {
      borderRadius: "100px !important",
    },
  },
  contained: {
    borderRadius: "8px !important",
    background: "#006298 !important",
    color: "white !important",
  },
  text: {
    color: "#006298 !important",
  },
  outlined: {
    color: "#006298 !important",
    border: "1px solid var(--Neutral-Gray---100, #DAE2EA) !important",
  },
  timeSlots: {
    display: "flex",
    gap: 8,
    overflow: "scroll",
  },

  timeSlotActions: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    width: 253,
  },
  bulletlist: {
    fontSize: 12,
    paddingLeft: 24,
    lineHeight: "150%",
    letterSpacing: 0.11,
    margin: 0,
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
}));

export function ScheduleConfirmationCard({ content, handleClick }) {
  const classes = useStyles();
  const [confirmed, setConfirmed] = useState(false);

  const confirmDropdown = (element) => {
    setConfirmed(true);
    handleClick(element);
  };

  const getElementPayload = (content) => {
    const body = {
      bulletlist: [],
    };
    {
      content.body.map((element) => {
        switch (element.type) {
          case "TextBlock":
            body.bulletlist.push(element);

          case "ColumnSet": {
            element?.columns?.map((column) => {
              {
                column.items.map((item) => {
                  if (column.subtype == "avatar") {
                    body.image = item;
                  } else {
                    body[item.subtype] = item;
                  }
                });
              }
            });
          }
        }
      });
    }
    return { body, actions: content.actions };
  };

  const getKeyValue = (text) => {
    const splitResult = text.split(":");
    const key = splitResult[0];
    const value = splitResult[1];
    return { key, value };
  };

  const { body, actions } = getElementPayload(content);

  return (
    <div className={classes.root}>
      <div className={classes.columns}>
        <div>
          <Avatar
            className={classes.avatar}
            sx={{
              height: 48,

              width: 48,

              border: "2px solid #DAE2EA",
            }}
            alt="Remy Sharp"
            src={body?.image?.url}
          />
        </div>
        <div>
          <Typography className={classes.title} variant="body2">
            {body?.title.text}
          </Typography>
          <Typography className={classes.subtitle} variant="caption">
            {body?.subtitle.text}
          </Typography>
        </div>
      </div>
      <div>
        <ul className={classes.bulletlist}>
          {body.bulletlist.map((item) => {
            return (
              <li>
                <strong>{getKeyValue(item.text).key}</strong>:{" "}
                {getKeyValue(item.text).value}
              </li>
            );
          })}
        </ul>
      </div>

      {!confirmed ? (
        <div className={classes.actions}>
          {actions.map((action) => {
            return (
              <CustomButton
                onClick={() => confirmDropdown(action)}
                variant={getVariant(action.title)}
                className={classes[getVariant(action.title)]}
                element={action}
              ></CustomButton>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

export function ScheduleTimeSlotSelection({ content, handleClick }) {
  const classes = useStyles();
  const [slotSelected, setSlotSelected] = useState(false);
  const slots = content?.actions;
  const onSlotSelected = (slot) => {
    handleClick(slot);
    setSlotSelected(true);
  };
  return (
    !slotSelected && (
      <div className={classes.timeSlots}>
        {slots.map((slot) => {
          return (
            <CustomButton
              variant="contained"
              className={classes.contained}
              onClick={onSlotSelected}
              element={slot}
            ></CustomButton>
          );
        })}
      </div>
    )
  );
}

export function TimeSlotsMenuSelection({ content, handleClick }) {
  const classes = useStyles();
  const actions = content?.actions;
  return (
    <div className={classes.timeSlotActions}>
      {actions.map((button) => {
        return (
          <CustomButton
            element={button}
            variant="outlined"
            className={classes.outlined}
            onClick={handleClick}
          />
        );
      })}
    </div>
  );
}
