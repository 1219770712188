export const getVariant = (text) => {
  switch (text) {
    case "Confirm":
    case "Confirm Details":
    case "Continue":
    case "Submit":
    case "Verify":
      return "contained";
    case "Resend":
    case "Skip":
      return "outlined";
    default:
      return "text";
  }
};
