export const getInitialsFromFirstNameOrFullNameAndLastName = (
  firstNameOrFullName,
  lastName,
) => {
  let safeFirstName = firstNameOrFullName ? firstNameOrFullName : ' ';
  let safeLastName = lastName ? lastName : ' ';

  if (!lastName) {
    const splittedFullName = firstNameOrFullName.trim().split(/\s/);
    if (splittedFullName.length > 1) {
      [safeFirstName, safeLastName] = splittedFullName;
    }
  }

  return (safeFirstName[0] + safeLastName[0]).trim();
}