import "./App.css";
import AzureChatBot from "./components/AzureChatBot";

function App() {
  return (
    <div>
      <AzureChatBot />
    </div>
  );
}

export default App;
