import React from "react";
import { makeStyles } from "@mui/styles";
import { CustomButton } from "./customButton";

const useStyles = makeStyles(() => ({
  tierTwoButton: {
    "&:not(:last-child)": {
      borderBottom: "1px solid #DAE2EA !important",
    },
  },
  list_button: {
    borderRadius: "8px !important",
    height: "52px !important",
  },
}));

export function TierTwoButton({ actions, handleChipClick }) {
  const classes = useStyles();

  const onClick = (action) => {
    if (action.url) {
      window?.open(action.url);
      action.data = {
        linkSelected: `${action.title}`,
      };
    }
    handleChipClick(action);
  };

  return (
    <div>
      {actions?.map((action) => (
        <div className={classes.tierTwoButton}>
          <CustomButton
            onClick={() => onClick(action)}
            element={action}
            className={classes.list_button}
          />
        </div>
      ))}
    </div>
  );
}
